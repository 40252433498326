
export const getValueFuzzy = (row:any, key: string) => {
    const foudnKey = Object.keys(row).find(x=>x.includes(key))
    if(foudnKey){
        return row[foudnKey]
    }
    return ""
}

export const getValueStripIndex = (row:any, key: string) => {
    const foudnKey = Object.keys(row).find(x=>{
        const strippedKey = x.replace(/^([0-9]+\.)+/g, '')
        return strippedKey == key
    })
    if(foudnKey){
        return row[foudnKey]
    }
    return ""
}